<template>
  <div class="home">
<!--    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->

  <h1>The Road to Ten Gigatons</h1>
  <h2>Carbon Removal Scale Up Challenge</h2>


  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  data(){
  	return {
  		forests: 0,
  		soil: 0,
  		dac: 0,
  		beccs: 0,
  	}
  },
}
</script>
